<template>
  <TTableAdvance
    :items="list"
    :fields="fields"
    :store="store"
    @click-clear-filter="clearFilter"
    resource="/customer/contract"
    reloadable
    enterable
    @click-reload="fetch"
  >
    <template #id="{ item }">
      <td>
        <TMessage :content="item.id" noTranslate bold />
      </td>
    </template>

    <template #status="{ item }">
      <td>
        <SMessageOrderStatus :id="item.status_id" />
      </td>
    </template>
    <template #purchase_cost_outstanding="{ item }">
      <td>
        <TMessageMoney
          :amount="item.purchase_cost_outstanding"
          :currency="item.purchase_cost_currency_id"
        />
      </td>
    </template>
    <template #service_fee_outstanding="{ item }">
      <td>
        <TMessageMoney
          :amount="item.service_fee_outstanding"
          :currency="item.purchase_cost_currency_id"
        />
      </td>
    </template>
    <template #service_fee="{ item }">
      <td>
        <TMessageMoney
          :amount="item.service_fee"
          :currency="item.service_fee_currency_id"
        />
      </td>
    </template>
    <template #transportation_company="{ item }">
      <td>
        <TMessage
          :content="item.transport_organization_id"
          noTranslate
          bold
          color="muted"
        />
      </td>
    </template>
    <template #purchasing_company="{ item }">
      <td>
        <TMessage
          :content="item.sales_organization_id"
          noTranslate
          bold
          color="muted"
        />
      </td>
    </template>

    <template #created_at="{ item }">
      <td>
        <TMessageDateTime :content="item.created_at" small />
      </td>
    </template>
    <template #id-filter>
      <TInputText :value.sync="filter.id" @update:value="filterChange" />
    </template>

    <template #status-filter>
      <SSelectOrderStatus
        store="order.statuses"
        :value.sync="filter.status_id"
        @change="filterChange"
      />
    </template>
    <template #transport_organization_id-filter>
      <SSelectOrganization
        :value.sync="filter.transport_organization_id"
        @change="filterChange"
      />
    </template>
    <template #sales_organization_id-filter>
      <SSelectOrganization
        :value.sync="filter.sales_organization_id"
        @change="filterChange"
      />
    </template>
    <template #created_at-filter>
      <TInputDateTimeRange
        :value.sync="dateRange"
        @update:value="setUpdatedAtFilter"
      />
    </template>
  </TTableAdvance>
</template>

<script>
export default {
  data() {
    return {
      store: "order.customer_contracts",
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Contract Id",
        },
        {
          key: "status",
          label: "Status",
          _classes: "text-nowrap",
          _style: "min-width: 150px",
        },

        {
          key: "transport_organization_id",
          label: "Transportation company",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },

        {
          key: "sales_organization_id",
          label: "Purchasing company",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },

        {
          key: "service_fee",
          label: "Service fee",
          _style: "min-width: 150px",
        },
        {
          key: "service_fee_outstanding",
          label: "Debt service fee",
          _style: "min-width: 150px",
        },
        {
          key: "purchase_cost_outstanding",
          label: "Debt for goods",
          _style: "min-width: 150px",
        },

        {
          key: "created_at",
          sorter: true,
          label: "Created at",
          _style: "width: 150px; max-width: 150px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    if (this.store) {
      this.$store.dispatch(`${this.store}.fetch.if-first-time`);
    }
  },
  computed: {
    list() {
      return this.$store.getters[`${this.store}.list`];
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch(`${this.store}.apply-query`, filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter_between = {};
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>